import Link from 'next/link'
import dynamic from 'next/dynamic'
import { faArrowToBottom as falArrowToBottom } from '@fortawesome/pro-solid-svg-icons/faArrowToBottom'
import { faFolder as falFolder } from '@fortawesome/pro-solid-svg-icons/faFolder'

const YouTubeEmbed = dynamic(() => import('../youtube_embed'))
const FontAwesomeIcon = dynamic(() =>
  import('@fortawesome/react-fontawesome').then((fa) => fa.FontAwesomeIcon)
)

export default function WorkflowAnalysis() {
  return <>
    <section className="workflow pt-5" id="workflow">
      <div className="container">
        <h2 className="title pb-lg-4">
          <small>AERODYNAMIC ANALYSIS</small> Analyze & improve your
          aerodynamic design yourself
        </h2>
        <div className="col-lg-8 offset-lg-2 mb-3">
          <YouTubeEmbed
            videoId="O_DNDxCtI_I"
            title="How to run an Aerodynamic Simulation"
          />
        </div>
        <div className="row row-cols-1 row-cols-lg-3 steps pt-5">
          <div className="col mb-3 mb-lg-0">
            <div className="card h-100 has-icon">
              <div className="icon upload" />
              <div className="card-body">
                <h3 className="card-title pb-5">Upload</h3>
                <p className="card-text m-0">Upload your 3D model</p>
                <ul className="pt-2">
                  <li className="pb-2">
                    Accepts{' '}
                    <Link href="/videos/save-weeks-run-aerodynamic-simulations-on-open-surface-models/12xA80foGXk">
                      non-watertight models.
                    </Link>
                  </li>
                  <li className="pb-2">Set wind speed & orientation</li>
                  <li className="pb-2">Choose simulation accuracy</li>
                  <li>Order & launch your wind tunnel simulation</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col mb-3 mb-lg-0">
            <div className="card h-100 has-icon">
              <div className="icon simulation" />
              <div className="card-body">
                <h3 className="card-title pb-5">Simulation</h3>
                <p className="card-text">
                  Our cloud-based virtual wind tunnel software will run a CFD
                  (Computational Fluid Dynamics) simulation around your 3D
                  model. You'll be looking at the results in no time.
                </p>
              </div>
            </div>
          </div>
          <div className="col mb-3 mb-lg-0">
            <div className="card h-100 has-icon">
              <div className="icon insight" />
              <div className="card-body">
                <h3 className="card-title pb-5">Improvement</h3>
                <p className="card-text m-0">Improve your design with:</p>
                <ul className="pt-2">
                  <li className="pb-2">Aerodynamic Simulation report</li>
                  <li className="pb-2">Online 3D results.</li>
                </ul>
                <div className="d-flex flex-column flex-xl-row justify-content-lg-around ">
                  <a
                    className="btn btn-sm btn-primary"
                    href="https://app.airshaper.com/simulations/23067/download/report"
                  >
                    <FontAwesomeIcon icon={falArrowToBottom} /> Sample Report
                  </a>
                  <a
                    className="btn btn-sm btn-primary mt-3 mt-xl-0"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://app.airshaper.com/projects/fixed-wing-drone"
                  >
                    <FontAwesomeIcon icon={falFolder} /> Sample Project
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>;
}
