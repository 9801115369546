import dynamic from 'next/dynamic'

const YouTubeEmbed = dynamic(() => import('../youtube_embed'))

export default function WorkflowOptimization() {
  return (
    <>
      <section className="workflow pt-5" id="workflow">
        <div className="container">
          <h2 className="title pb-lg-4">
            <small>AERODYNAMIC SHAPE OPTIMIZATION</small>Let our Aerodynamic
            Shape Optimization
            <br />
            <i>morph your design</i>
          </h2>
          <div className="col-lg-8 offset-lg-2 mb-3">
            <YouTubeEmbed videoId="-fBXwx_n10I" />
          </div>
        </div>
      </section>
    </>
  )
}
