import dynamic from 'next/dynamic'
import Intro from 'components/home/intro'
import WorkflowAnalysis from 'components/home/workflow'
import WorkflowOptimization from 'components/home/optimization'
import Navigation from 'components/nav'
import Consent from 'components/consent'
const Focus = dynamic(() => import('components/home/focus'))
const Testimonials = dynamic(() => import('components/home/testimonials'))
const Cases = dynamic(() => import('components/home/cases'))
const Report = dynamic(() => import('components/home/report'))
const Footer = dynamic(() => import('components/footer'))

export default function Index() {
  return (
    <>
      <div className="home">
        <div className="container-fluid px-0 h-100">
          <div className="nav-header container-lg mx-auto">
            <header>
              <div className="scroll-msg-container d-none d-lg-block">
                <div className="scroll-msg-inner">
                  <div className="scroll-msg-wheel" />
                </div>
              </div>
            </header>
            <Navigation isCase={false} />
          </div>
        </div>
        <div className="container-fluid container-intro px-0">
          <Intro />
        </div>
        <div className="container-fluid px-0">
          <WorkflowAnalysis />
          <WorkflowOptimization />
          <Focus />
          <Testimonials />
          <Cases />
          <Report />
          <Footer showCredits={true} />
        </div>
      </div>
      <Consent />
    </>
  )
}
